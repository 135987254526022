/* Grundlegende App-Struktur */
.app-container {
  position: relative;
  width: 100vw; 
  height: 100vh;
  overflow: hidden;
}

/* Karten-Container */
.map-container {
  width: 100%; 
  height: 100%;
}

/* To-Do Liste Container */
.list-container {
  position: absolute;
  top: 0;
  right: 0;
  width: 400px;
  height: 100vh;
  overflow-y: auto;
  background-color: rgba(255, 255, 255, 0.665);
  padding: 20px;
  box-shadow: -2px 0 10px rgba(0,0,0,0.1);
  z-index: 1000;
}

/* Drag and Drop Liste Styles */
.draggable-list-container {
  display: flex;
  flex-direction: column;
}

.draggable-list-title {
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: bold;
}

.draggable-list-input-container {
  display: flex;
  margin-bottom: 15px;
}

.draggable-list-input {
  flex-grow: 1;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

.draggable-list-button {
  margin-left: 10px;
  padding: 8px 15px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.draggable-list-button:hover {
  background-color: #45a049;
}

.draggable-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.draggable-list-item {
  padding: 10px;
  margin-bottom: 8px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: move;
}

.draggable-list-item.is-dragging {
  opacity: 0.5;
  background-color: #e0e0e0;
}

/* Mapbox spezifische Styles */
.mapboxgl-ctrl-top-right {
  right: 50px !important;
}

.mapboxgl-ctrl-group {
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
}

/* Scrollbar Styles für die Liste */
.list-container::-webkit-scrollbar {
  width: 8px;
}

.list-container::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.list-container::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.list-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Responsive Design für kleinere Bildschirme */
@media (max-width: 768px) {
  .list-container {
    width: 100%;
    height: 50vh;
    top: 50vh;
  }

  .mapboxgl-ctrl-top-right {
    right: 10px !important;
  }
}

/* Bestehende Styles bleiben unverändert */

.draggable-list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  margin-bottom: 5px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: grab;
}

.draggable-list-item span {
  flex-grow: 1;
  margin-right: 10px;
}

.delete-button {
  background-color: #ff4d4d;
  color: white;
  border: none;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

.delete-button:hover {
  background-color: #ff1a1a;
}

.draggable-list-item.is-dragging {
  opacity: 0.5;
  background-color: #e0e0e0;
}


.search-container {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 1;
}

.search-input {
  padding: 5px;
  width: 200px;
  border: 1px solid #ccc;
  border-radius: 4px;
}